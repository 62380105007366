/**
 * Created V/12/04/2019
 * Updated S/14/01/2023
 *
 * Copyright 2019-2023 | Fabrice Creuzot <fabrice~cellublue~com>
 * Copyright 2019-2022 | Jérôme Siau <jerome~cellublue~com>
 * https://github.com/kyrena/openmage-shippingmax
 *
 * This program is free software, you can redistribute it or modify
 * it under the terms of the GNU General Public License (GPL) as published
 * by the free software foundation, either version 2 of the license, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but without any warranty, without even the implied warranty of
 * merchantability or fitness for a particular purpose. See the
 * GNU General Public License (GPL) for more details.
 */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& global &&& */
#shippingmaxDialog * { margin:0; padding:0; }

#shippingmaxDialog {
	position:fixed; top:0; right:0; bottom:0; left:0; width:100%; height:100%; text-align:center; z-index:99000;
	background-color:rgba(0, 0, 0, 0.65); opacity:1;
}

#shippingmaxBox {
	position:relative; top:5%; display:inline-flex; width:85vw; max-width:inherit; height:80%; overflow:hidden;
	border-radius:0; background-color:#FFF; box-shadow:0 0 2.5em #111; opacity:1;
}

#shippingmaxBox object, #shippingmaxBox div.loader { position:absolute; top:0; right:0; bottom:0; left:0; width:100%; height:100%; }
#shippingmaxBox object.loader + div.loader { display:flex; align-items:center; justify-content:center; }

#shippingmaxBox div.loader { display:none; font-size:80px; opacity:0.5; background-color:black; cursor:wait; }

@media (max-width:991px) {
	#shippingmaxBox { top:0; width:100vw; height:100%; }
}

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& checkout &&& */
.shippingmax .last {
	display:inline-block; padding:0.2em 0.4em; width:auto;
	font-size:0.85em; border-radius:5px; color:#666; background-color:#EEE;
}